import { PageMetaDefaults } from '@/components/global/Head';
import Page, { getPageProps, PageProps } from '@/components/global/page';
import pageQuery from '@/queries/page/getGamesPageByUri.graphql';
import { CmsSite } from '@/types/pbskids-graph';

export async function getServerSideProps(context: { params: { 'uriParts': string[]; }; }) {
  return getPageProps(
    context,
    pageQuery,
    CmsSite.GamesWebEn,
    PageMetaDefaults.DEFAULT_GAME_PAGE_DESCRIPTION,
    {
      verb: 'Play',
      noun: 'Games',
    },
    'game',
  );
}

export default function GamePage(props: PageProps) {
  // eslint-disable-next-line new-cap
  return Page(props);
}
